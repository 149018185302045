import axios from 'axios';

console.log(process, process.env);

const { env } = process;
console.log(env === process.env);
const service = axios.create({
  // headers: {
  //   'Content-Type': 'application/x-www-form-urlencoded',
  // },
  // baseURL: '/api',
  baseURL: process.env.NODE_ENV === 'development' ? '/api' : '',
  timeout: 20000,
  // transformRequest: [(data) => {
  //   let tResult;
  //   if (data) {
  //     const keys = Object.keys(data);
  //     const t = [];
  //     keys.forEach((item) => {
  //       t.push(`${item}=${data[item]}`);
  //     });
  //     tResult = t.join('&');
  //   } else {
  //     tResult = data;
  //   }
  //   return tResult;
  // }],
});

/* 过滤请求 */
service.interceptors.request.use((config) => config);

/* 过滤响应 */
service.interceptors.response.use((result) => result.data, (err) => Promise.reject(err));

export default service;
